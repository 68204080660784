<template>
  <div class="vlive-errors">
    <p>{{message}}</p>
  </div>
</template>

<script>
export default {
  props: ["message"],
  data() {
    return {};
  }
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
.vlive-errors {
  background-color: #1a1a1a;
  text-align: center;
  padding: 3rem;
  font-size: 14px;
  font-weight: 500;
  color: white;
  height: 100vh;
  font-family: $font-regular;
}
</style>